// Page Builder
.fl-builder {
	.wrap.container {
		width: 100%;
		padding: 0;

		> .content {
			margin: 0; }

		.main {
			padding: 0; } } }

.fl-row-content-wrap {
		padding: 0;

		.fl-col-content {
			padding: 5vw 0 4vw; } }

.row-bg-30p {
	.fl-row-content-wrap {
		background-size: 30% auto !important;

		@media screen and (max-width: 768px) {
			background-image: none; } } }

.row-bg-60p {
	.fl-row-content-wrap {
		background-size: 60% auto !important;

		@media screen and (max-width: 1200px) {
			background-size: auto 100% !important; }

		@media screen and (max-width: 768px) {
			background-image: none; } } }

.row-bg {
	.fl-row-content-wrap {
		background-size: auto 100% !important;

		@media screen and (max-width: 768px) {
			background-image: none; } } }

.no-padding {
	.fl-col-content {
		padding: 0; } }

.service-row {
	.fl-col {
		position: relative;
		min-height: 350px; } }

// Inner Page
.header-img {
	.fl-row-content-wrap {
		padding: 0; }

	.fl-col-content {
 } }		// padding: 10vw 0

// Home Page
.home-header {
	.fl-row-content-wrap {
		padding: 0; }

	.fl-col-content {
		padding: 8vw 0 25vw; } }

.tagline {
	text-transform: uppercase;
	font-size: 1.8rem;
	font-weight: 500;
	letter-spacing: 0.1em; }

.home-down-arrow {
	text-align: center;
	font-size: 3rem;

	i {
		animation: pulse 5s infinite; } }

@keyframes pulse {
	0% {
		transform: translateY(0%); }

	50% {
		transform: translateY(25%); }

	100% {
		transform: translateY(0%); } }

// Home Page
.testimonial-wrapper {
	background: rgba(29, 45, 70, 0.65);
	border-top: solid 1px #fff;
	border-bottom: solid 1px #fff;
	color: #ffffff;
	text-shadow: 1px 1px 2px #000;
	letter-spacing: 0.05em;

	.testimonial_rotator_prev, .testimonial_rotator_next {
		color: #FFF;

		@media screen and (max-width: 768px) {
			bottom: 0;
			top: auto; } }

	.testimonial_rotator_wrap.with-prevnext {
		@media screen and (max-width: 768px) {
			margin: 0;
			padding: 0; } } }

.testimonial-wrapper .testimonial_rotator.template-longform {
	.testimonial_rotator_author_info {
		margin: 0;
		padding: 0;

		p {
			margin: 0;
			font-size: 1.5em; } }

	hr.longform_hr {
		height: 1px; } }

// Plumbing Page
.plumbing-service-text .fl-col-content {
	max-width: 60rem;
	margin-left: 10%; }

// Contact Page

.contact-form {
	input, textarea, select {
		border: solid 2px #eee; }

	nf-field {
		display: block;

		&:nth-child(2) {
			float: left;
			width: 49%; }

		&:nth-child(3) {
			float: right;
			width: 49%; } } }

#contact-map {
	height: 400px;
	width: 100%;
	margin: -5vw 0 -4.5vw;

	@media screen and (max-width: 768px) {
		width: 100%;
		max-height: 50vh; } }

.gm-style-iw * {
	display: block;
	width: 100%; }

.gm-style-iw h4, .gm-style-iw p {
	margin: 0;
	padding: 0; }

.gm-style-iw a {
	color: #4272db; }

.home #contact-map {
	margin: -5vw 0 -4vw;
	width: 50vw;
	position: relative;

	&:before {
		display: block;
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 50px 0 50px 50px;
		border-color: transparent transparent transparent $brand-primary;
		position: absolute;
		left: 0;
		top: 50%;
		content: "";
		z-index: 90;
		transform: translateY(-50%); }

	@media screen and (max-width: 768px) {
		width: 100%;

		&:before {
			display: none; } } }

// 404 Page
.fl-builder.search .wrap.container, .error404 {
	.main {
		padding: 2% 0; } }
