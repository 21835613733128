// Service Modules

.fl-module-le-service-block {
	background: {
		size: cover;
		position: center; }
	position: absolute;
	color: #fff;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;

	.service-container {
		position: absolute;
		bottom: 1em;
		overflow: hidden; }

	.service-title {
		font-weight: 300;
		display: inline-block;
		max-width: 20rem;
		border-bottom: solid 1px #fff;
		padding-bottom: 1.5rem; }

	.service-desc {
		max-height: 0;
		transition: all 0.5s ease-in-out;
		overflow: hidden;

		@media screen and (max-width: 992px) {
			max-height: none; }

		ul, ol {
			margin: 0; } }

	&:hover {
		.service-desc {
			max-height: 300px; } } }
