footer.content-info {
	position: static;
	background: #ededed;
	right: 0;
	bottom: 0;
	left: 0;
	padding: 2.5rem 0;
	color: $secondary-dark;
	font: {
		size: 1.1rem; }

	.container {
		padding: 1.5rem;
		border: {
			top: solid 1px currentColor;
			bottom: solid 1px currentColor; } }

	.menu {
		padding: 0;
		margin: 0;
		list-style: none; }

	a {
		&:link, &:visited {
			color: currentColor; }

		&:active, &:hover, &:focus {
			color: $brand-primary;
			text-decoration: none; } }

	.footer-logo {
		display: block;
		line-height: 7.5rem;

		path.text {
			fill: initial; } } }

// Alternate footer
.home {
	footer.content-info {
		position: absolute;
		background: none;
		color: #fff;

		.footer-logo path.text {
			fill: #fff; }

		@media screen and (max-width: 768px) {
			position: relative;
			padding: 0;
			margin: 0;
			font-size: 1.4rem;
			text-align: center;

			.container {
				background: $secondary-dark; } } } }


.fl-builder-edit {
	.home {
		footer.content-info {
			position: relative;
			top: -175px; } } }
