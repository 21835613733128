.foogallery-thumbnail {
	position: relative;

	a {
		border: 0 !important;
		box-shadow: none !important;
		display: block !important;
		margin: 0 !important;

		&:hover {
			border: 0 !important; }

		img {
			width: 100%; } }

	.fbx-link:after {
		position: absolute;
		content: "";
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		box-shadow: inset 5px 5px 20px 0px rgba(0,0,0,0.5); } }
