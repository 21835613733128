body {
	font: {
		family: 'futura-pt', 'Helvetica', 'Arial';
		size: 1.6rem;
		weight: 300; }
	color: $secondary-dark;
	position: relative; }

b, strong {
	font-weight: 500;
	font-size: 1.125em; }

p:empty {
	display: none;
	visibility: hidden; }

h1 {
	font: {
		family: "trajan-pro-3", sans-serif;
		size: 5rem; }

	@media screen and (max-width: 768px) {
		font-size: 3rem; } }


h2 {
	text-transform: uppercase; }

h3 {
	text-transform: uppercase;

	a:hover, a:active, a:focus {
		text-decoration: none; } }

.lead {
	font-size: 2.8rem;
	font-weight: 300;

	@media screen and (max-width: 768px) {
		font-size: 2rem; } }

hr {
	max-width: 30rem;
	margin: 2rem auto; }

.title-section {
	h2, h3 {
		margin: 0.75rem 0;

		a:link, a:visited {
			color: currentColor; } }

	h2 {
		font: {
			size: 3.4rem;
			weight: 400; }

		@media screen and (max-width: 768px) {
			font-size: 2.6rem; } }

	h3 {
		font: {
			size: 1.8rem;
			weight: 300; }
		color: inherit !important;
		letter-spacing: 0.125em;

		@media screen and (max-width: 768px) {
			font-size: 1.6rem; }

		strong {
			font-weight: 600;
			size: 1em; } }

	hr {
		margin: 1rem 0;
		border-color: inherit; } }

.uppercase {
	text-transform: uppercase !important; }

.icon {
  display: inline-block;
  width: 22px;
  height: 22px;
  vertical-align: bottom;
  stroke-width: 0;
  stroke: currentColor;
  fill: currentColor; }
