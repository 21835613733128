header.banner {
	box-shadow: 0px -5px 20px #c3c3c3;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 100;
	background: #fff;

	@media screen and (max-width: 991px) {
		display: none; } }

.row--flex {
	display: flex;
	justify-content: space-between;

	&:before, &:after {
		display: none; }

	.custom-logo {
		max-width: 275px;
		margin-right: 1rem;
		height: auto;

		@media screen and (max-width: 1200px) {
			max-width: 200px; }

		&-link {
			display: inline-block;
			padding: 2rem 0;
			transition: all 0.2s ease-in-out; } }

	.nav {
		display: table;
		font-size: 1rem;
		font-weight: 700;

		&-primary {
			display: inline-block;
			vertical-align: middle; }

		&-secondary {
			display: inline-block;
			vertical-align: middle;
			float: right; }

		>li {
			display: table-cell;
			text-align: center;
			border-right: solid 1px $secondary-light;

			&:first-of-type {
				border-left: solid 1px $secondary-light; }

			&.menu-home {
				a:before {
					background: url('../../dist/images/nav-icon-sprite-c.png') no-repeat 0 0;
					width: 36px;
					height: 31px; } }

			&.menu-heating-air-conditioning {
				a:before {
					background: url('../../dist/images/nav-icon-sprite-c.png') no-repeat -51px 0;
					width: 28px;
					height: 31px; } }

			&.menu-plumbing-gas-fitting {
				a:before {
					background: url('../../dist/images/nav-icon-sprite-c.png') no-repeat -96px 0;
					width: 31px;
					height: 31px; } }

			&.menu-contact-us {
				a:before {
					background: url('../../dist/images/nav-icon-sprite-c.png') no-repeat -139px 0;
					width: 37px;
					height: 31px; } }

			&.menu-recommended-contractors {
				a:before {
					background: url('../../dist/images/nav-icon-sprite-c.png') no-repeat -270px 0;
					width: 37px;
					height: 31px; }

				&.active a {
					background: none;

					&:link, &:visited {
						color: $secondary-light; }

					&:hover, &:active, &:focus {
						color: #fff;
						background: $brand-primary; }

					&:before {
						background: url('../../dist/images/nav-icon-sprite-c.png') no-repeat -270px 0; } } }

			&.menu-24hr-emergency-services {
				a:link, a:visited {
					color: #a50004; }

				a:hover, a:focus, a:active {
					color: #fff; }

				a:before {
					background: url('../../dist/images/nav-icon-sprite-c.png') no-repeat -190px -1px;
					width: 29px;
					height: 31px; } }

			&.menu-request-a-quote {
				a:link, a:visited {
					color: #26327a; }

				a:hover, a:focus, a:active {
					color: #fff; }

				a:before {
					background: url('../../dist/images/nav-icon-sprite-c.png') no-repeat -236px -1px;
					width: 30px;
					height: 31px; } }

			&.menu-facebook {
				a:before {
					background: url('../../dist/images/nav-icon-sprite-c.png') no-repeat -312px 0px;
					width: 37px;
					height: 31px; } }


			&.active {
				a {
					background: $brand-primary;

					&:link, &:visited {
						color: #FFF; }

					&:before {
						background-image: url('../../dist/images/nav-icon-sprite-hover-c.png'); } } }

			a {
				width: 10.5rem;
				height: 8.4rem;
				padding: 1rem;
				transition: all 0.2s ease-in-out;

				&:link, &:visited {
					color: $secondary-light; }

				&:hover, &:active, &:focus {
					color: #fff;
					background: $brand-primary;

					&:before {
						background-image: url('../../dist/images/nav-icon-sprite-hover-c.png') !important; } }

				&:before {
					display: block;
					content: "";
					width: 36px;
					height: 36px;
					margin: 0 auto 5px;
					background: #000;
					transition: height 0.2s ease-in-out; }

				@media screen and (max-width: 1200px) {
					width: 9rem;
					padding: 1rem 0; } } } } }

header.banner .nav-secondary .nav>li {
	&.active a:link, &.active a:visited {
		background: #fff; }

	&.active a:hover, &.active a:active, &.active a:focus {
		background: $brand-primary;
		color: #fff !important; }

	&.menu-24hr-emergency-services {
		a:before {
			background: url('../../dist/images/nav-icon-sprite-c.png') no-repeat -190px -1px; }

		&.active {
			a:link, a:visited {
				color: #a50004; } } }

	&.menu-request-a-quote {
		a:before {
			background: url('../../dist/images/nav-icon-sprite-c.png') no-repeat -236px -1px; }

		&.active {
			a:link, a:visited {
				color: #26327a; } } } }

header.banner.fixed-top {
	.row--flex .nav>li a {
		height: 5.3rem;

		&:before {
			height: 0; } }

	.custom-logo-link {
		padding: 0;
		line-height: 5rem; } }

.admin-bar {
	header.banner {
		top: 32px; } }

// ShiftNav
#shiftnav-toggle-main {
	background: #fff !important;
	box-shadow: 0px -5px 20px #c3c3c3 !important;

	a, i {
		color: $brand-dark !important; }

 .shiftnav-main-toggle-content {
		padding: 1.5rem 0 !important; }

	@media screen and (min-width: 992px) {
		display: none; } }

.shiftnav-wrap {
	z-index: auto !important;

	@media only screen and (min-width: 992px) {
		padding-top: 8.4rem !important; }

	@media only screen and (max-width: 991px) {
		padding-top: 5.2rem !important; } }

.shiftnav-menu-title {
	padding: 1.5rem !important;

	a {
		color: transparent !important;
		background: url(/wp-content/uploads/2016/09/liquid-empire-logo.png);
		background-repeat: no-repeat;
		background-size: contain;
		height: 5rem;
		display: block; } }
