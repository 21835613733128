// Search form
.search-form {
  @extend .form-inline;
}
.search-form label {
  font-weight: normal;
  @extend .form-group;
}
.search-form .search-field {
  @extend .form-control;
}
.search-form .search-submit {
  @extend .btn;
  @extend .btn-default;
}

// Contact form
.contact-form #nf-field-4 {
    background: #26327a;
    border: none;
    color: #fff;
    padding: 0.5em 2em;
    font-size: 1.8rem;
    border-radius: 3px;
	transform: 0.2s all ease-in-out;
}

.contact-form #nf-field-4:hover {
	background: #00aae9;
}