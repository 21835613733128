// Glyphicons font path
$icon-font-path:        "../fonts/";

// Grid settings
$main-sm-columns:       12;
$sidebar-sm-columns:    4;

// Colors
$brand-primary:   #00aae9;
$brand-dark:      #26327a;
$secondary-light: #a7a9ac;
$secondary-dark:  #57585b;
